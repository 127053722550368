import About from "../components/About";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Services2 from "./Services2";
import Platform from "./Platform";
function Main() {
  return (
    <>
      <main>
        <Services />
        <Services2 />
        <Platform />
        <About />
        <Contact />
      </main>
    </>
  );
}
export default Main;
