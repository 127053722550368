import ProfilePDF from "../assets/ProfilePDF.pdf"
function About() {
  return (
    <>
      <div
        style={{ marginTop: "15rem", width: "100%", height: "10px" }}
        className="about-scroll"
      ></div>
      <div
        style={{
          margin: "auto",
        }}
      >
        <div
          className="container about"
          style={{
            alignItems: "center",
          }}
        >
          <div className="row">
            <div
              className="col-md-6 text-center"
              style={{
                margin: "auto",
                height: "60%",
              }}
            >
              <img
                alt="about"
                src={require("../assets/PR.png")}
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <h2 className="main-title about-h2">ABOUT US</h2>
              <p className="main-p">
                At the heart of our software development company is a diverse
                and dynamic team of senior software engineers, designers, and
                strategists who share a common goal: to create world-class
                software that sets your business apart. Our people are not just
                tech gurus but also creative thinkers and problem solvers
                committed to understanding your unique challenges and delivering
                tailored solutions that exceed expectations.
              </p>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "60px" }}>
            <a
              href={ProfilePDF}
              download
             className="styled-link"
            >
              Download Company Profile
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;