function Platform() {
  return (
    <>
      <div
        style={{ marginTop: "15rem", width: "100%", height: "10px" }}
        className="about-scroll"
      ></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6">
            <h2 className="main-title about-h2">PR Camps Platform</h2>
            <p className="main-p">
              PR Camps is a comprehensive platform designed to revolutionize the
              way camps and courses are managed and delivered. Our platform
              empowers organizers to create, manage, and promote camps and
              educational programs with ease, offering a seamless experience for
              both instructors and participants. With tools for scheduling,
              registration, and communication, we help streamline operations and
              enhance engagement, ensuring a successful experience for all.
              Whether you're hosting a summer camp, educational workshop, or
              online course, PR Camps provides the flexibility and features you
              need to make it a success.
              <br />
              <a href="https://camps.progrhyming.com" target="_blank" className="styled-link">
                Check PR Camps Platform
              </a>
            </p>
          </div>

          <div
            className="col-md-6 text-center"
            style={{
              margin: "auto",
              height: "60%",
            }}
          >
            <img
              alt="platform"
              src={require("../assets/Camps.png")}
              className="img-fluid"
              style={{
                margin: "auto",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Platform;
