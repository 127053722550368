import React, { useState } from 'react';

function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate form data
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.subject) newErrors.subject = 'Subject is required';
    if (!formData.message) newErrors.message = 'Message is required';

    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    setErrors({}); // Clear any previous errors

    try {
      const response = await fetch('https://formspree.io/f/mvgpelnr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        }); // Reset form after successful submission
      } else {
        alert('There was an error sending your message.');
      }
    } catch (error) {
      alert('Error: Unable to send message.');
    } finally {
      setIsLoading(false); // Stop loading state
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container contact">
        <h2 className="main-title text-center">CONTACT</h2>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-1">
              <input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Name"
                className={`contact-input ${errors.name ? 'error' : ''}`}
              />
              {errors.name && <p className="error-text">{errors.name}</p>}
            </div>

            <div className="col-md-4 mb-1">
              <input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                className={`contact-input ${errors.email ? 'error' : ''}`}
              />
              {errors.email && <p className="error-text">{errors.email}</p>}
            </div>

            <div className="col-md-4 mb-1">
              <input
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                placeholder="Subject"
                className={`contact-input ${errors.subject ? 'error' : ''}`}
              />
              {errors.subject && <p className="error-text">{errors.subject}</p>}
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12">
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="Message"
            className={`contact-textarea ${errors.message ? 'error' : ''}`}
          />
          {errors.message && <p className="error-text">{errors.message}</p>}
        </div>

        <br />
        <div className="row">
          <div className="col-md-12">
            {isLoading ? (
              <button className="form-btn" disabled>
                Loading...
              </button>
            ) : (
              <button className="form-btn" type="submit">
                Send Email
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default Contact;
