// import Card from "./Card";

// function Services() {
//   return (
//     <div className="container services">
//       <h2 className="main-title text-center">Our Services</h2>
//       <div className="card-cover">
//         <div className="col-md-12">
//           <div className="row">
//             <div className="col-md-4 mb-2">
//               <Card
//                 title="E-commerce"
//                 img={require("../assets/E-commerce.jpg")}
//               />
//             </div>
//             <div className="col-md-4 mb-2">
//               <Card
//                 title="IT Consulting"
//                 img={require("../assets/IT Consulting.jpg")}
//               />
//             </div>
//             <div className="col-md-4 mb-2">
//               <Card
//                 title="Website Devlopment"
//                 img={require("../assets/Website.jpg")}
//               />
//               <Card
//                 title="Training and Technical Support"
//                 img={require("../assets/Training and Technical Support.jpg")}
//               />
//               <Card
//                 title="Custom Software Development"
//                 img={require("../assets/Custom Software Development.jpg")}
//               />
//               <Card
//                 title="DeveIopment Application MobiIe"
//                 img={require("../assets/DeveIopment Application MobiIe.jpg")}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Services;
import Card from "./Card";

function Services() {
  return (
    <div className="container services">
      <h2 className="main-title text-center">Our Services</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-2">
              <Card
                title="E-commerce"
                img={require("../assets/E-commerce.jpg")}
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="IT Consulting"
                img={require("../assets/IT Consulting.jpg")}
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Website Development"
                img={require("../assets/Website.jpg")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <Card
                title="Training and Technical Support"
                img={require("../assets/Training and Technical Support.jpg")}
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Custom Software Development"
                img={require("../assets/Custom Software Development.jpg")}
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Development Application Mobile"
                img={require("../assets/DeveIopment Application MobiIe.jpg")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;