
import { Link, animateScroll as scroll } from "react-scroll";

function Footer() {
  return (
    <footer className="navopened">
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">
                  <font color="#f59022">P</font>
                  <font color="#0d5aa4">rog</font>
                  <font color="#f59022">R</font>
                  <font color="#0d5aa4">hyming</font>
                </h1>
                <p className="footer-text">
                🌐 Code Your Future with Us! 🚀
                </p>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Important Link</p>
                <ul>
                  <li>
                    <Link spy={true} smooth={true} duration={1000} to="headerbg">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link spy={true} smooth={true} duration={1000} to="services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link spy={true} smooth={true} duration={1000} to="about-scroll">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link spy={true} smooth={true} duration={1000} to="contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <a href="mailto:prgrhyming@outlook.com">prgrhyming@outlook.com</a>
                  </li>
                  <li>
                    <a href="#">Syria - Lattakia</a>
                  </li>
                  <li>
                    <a href="tel:+963936">+963 959 532 399</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/progrhyming?igsh=dnJudmF6azlnb2k1">
                      Instagram
                    </a>
                  </li>
                  {/* <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/burhankocadag0">
                      Facebook
                    </a>
                  </li> */}
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/progrhyming/">
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/963936099687?text=hello" target="_blank" rel="noopener noreferrer">
                      WhatsApp
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} className="gotop">
        <i className="fas fa-level-up-alt"></i>
      </button>
    </footer>
  );
}

export default Footer;