import vid from '../assets/a.mp4'
function Header() {
  return (
    <header>
      <video src={vid} loop autoPlay muted></video>
      <h1  style={
        
        {fontWeight : "75px"}}><font color = "#f59022">P</font><font color = "#0d5aa4">rog
        </font>
        <font color = "#f59022">R</font>
        <font color = "#0d5aa4">hyming</font></h1>
      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
