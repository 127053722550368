import Card from "./Card";
import AlAminPNG from "../assets/almin.png";
import KangoPNG from "../assets/Kango.png";
import ShamseenPNG from "../assets/شمسين.png";
import SalPNG from "../assets/صلحلي.png";
import InstPNG from "../assets/معهد.png";
import kinPNG from "../assets/روضة.png";
function Services2() {
  return (
    <div className="container services">
      <h2 className="main-title text-center">Our Projects</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-2">
              <Card
                title="Shamseen System (UAE)"
                img={ShamseenPNG}
                text="An administrative system for monitoring point-of-sale transactions that consists of 5 administrative applications (Management – Point of Sale – External Sources – Representatives – Ministry – Parents) enables management to view all invoices and sales and purchase statuses related to the points of sale, and allows parents to see the items purchased by their children"
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Kango"
                img={KangoPNG}
                text="An educational platform composed of two applications (Management Application – User Application) that features a high-level encryption and protection system for content, as well as an automated question-and-answer system in the User Application."
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Al Amin Edu"
                img={AlAminPNG}
                text="An educational platform consisting of two applications (Management Application – User Application) characterized by a high encryption and protection system for content (whether it is video clips or PDF files)."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <Card
                title="Salhly"
                img={SalPNG}
                text="A local system that provides technical services, including maintenance operations for all household faults or even faults that may occur within commercial institutions."
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Institute"
                img={InstPNG}
                text="Financial administrative system for monitoring payment processes with a service for inserting advertisements specific to the educational institution that is displayed to subscribers, along with the feature of sending instant notifications."
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Kindergarten management system"
                img={kinPNG}
                text="It consists of 4 applications (Admin - Supervisor - User (Parents) - Bus Supervisor). Through this system, one can monitor the child's status, view and manage their financial situation, as well as check the daily attendance schedule and the events that the child will attend"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services2;
