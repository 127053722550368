import { useState } from "react";

function truncate(str, n) {
  return str?.length > n ? str.substr(0, n - 1) + '...' : str;
}

function Card(props) {
  const [expanded, setExpanded] = useState(false);
  
  return (
    <div className="card" style={{ padding: "10px 5px" }}>
      <br />
      <div className="text-center">
        <img alt="card-img" src={props.img} className="text-center img-fluid" />
      </div>
      <div className="text-center">
        <h3 className="card-title fontHead">{props.title}</h3>
      </div>
      <div className="p-3">
        <p className="card-text">
          {props.text && (expanded ? props.text : truncate(props.text, 100))}
        </p>
        {props.text && (
          <a style={{ color: "#f59022" }} onClick={() => setExpanded(!expanded)}>
            {expanded ? "Read less ....." : "Read more ....." }
          </a>
        )}
      </div>
    </div>
  );
}

export default Card;